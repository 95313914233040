@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100..900&family=Zen+Maru+Gothic&display=swap');

:root {
  --logoColor-g: #8fc31f;
  --logoColor-y: #f8b73d;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif, "Noto Sans JP", "Zen Maru Gothic", serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  font-family: "Noto Sans JP";
}



div#root::before {
  position: fixed;
  content: "";
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-image: url(https://hikaru-acu.com/images/background4.jpg);
  z-index: -3;
  background-size: contain;
  background-position: contain;
  opacity: 0.3;
}

/* body::before {
  position: fixed;
  content: "";
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-image: url(https://hikaru-acu.com/images/background4.jpg);
  z-index: -3;
  background-size: contain;
  background-position: contain;
  opacity: 0.3;
} */

.background-white {
  background-color: white;
}

.box-shadow {
  box-shadow: 0px 0px 32px -8px #777777;
  border-radius: 5px;
}



.layoutHome {
  margin: 0 auto;
  max-width: 960px;
  min-height: 150vh;
  padding: 0px 20px 40px;
  /* background-color: #eee; */
}

.layoutPage {
  margin: 0 auto;
  max-width: 960px;
  margin-top: 80px;
  /* min-height: 150vh; */

  padding: 0px 20px 40px;
  /* background-color: #eee; */
}

.layout2 {
  margin: 0 auto;
  max-width: 960px;
  margin-bottom: 80px;
  padding: 80px 20px;
  height: 150vh;
  /* background-color: #eee; */
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  list-style: none;
  flex-wrap: wrap;
}

.link {
  padding: 4px 12px;
  color: #000;
  font-size: 14;
  transition: all 0.3;
}

.link:hover {
  opacity: 0.7;
}

.table {
  margin-top: 20px;
  width: 300px;
  border: 1px solid #000;
}

.title {
  padding: 4px 12px;
  background-color: #ccc;
  border: 1px solid #000;
}

.item {
  padding: 4px 12px;
  border: 1px solid #000;
}

.navLink {
  transition: all 0.3s;
}

.navLink:hover {
  opacity: 0.3 !important;
  text-decoration: underline;
}

@media screen and (max-width: 480px) {
  .layoutHome {
    margin: 0 auto;
    max-width: 960px;
    min-height: 150vh;
    padding: 0px 15px 40px;
  }

  .layoutPage {
    margin: 0 auto;
    max-width: 960px;
    margin-top: 80px;
    min-height: 150vh;
    padding: 0px 15px 40px;
  }

  .googlemap_wrapper iframe {
    aspect-ratio: 1/1;
  }

  .write_vertical {
    writing-mode: vertical-rl;
  }
}