.singleNews .article {
    width: 100%;
}

/************************************
** WordPress Misc
************************************/
iframe {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
}

.aligncenter {
    display: block;
    margin-right: auto;
    margin-left: auto;
}

.alignleft {
    float: left;
    margin-right: 25px;
}

.alignright {
    float: right;
    margin-left: 25px;
}

.wp-caption {
    padding-top: 4px;
    border: 1px solid var(--cocoon-x-thin-color);
    border-radius: 3px;
    background-color: var(--cocoon-xx-thin-color);
    text-align: center;
    max-width: 100%;
}

.wp-caption .wp-caption-text,
.gallery .gallery-caption {
    font-size: 0.8em;
    margin: 2px;
}

.wp-caption img {
    margin: 0;
    padding: 0;
    border: 0 none;
}

.wp-caption-dd {
    margin: 0;
    padding: 0 4px 5px;
    font-size: 11px;
    line-height: 17px;
}

img.alignright {
    display: inline-block;
    margin: 0 0 1em 1.5em;
}

img.alignleft {
    display: inline-block;
    margin: 0 1.5em 1em 0;
}

.comment-btn {
    margin: 24px 0;
    cursor: pointer;
}

label {
    cursor: pointer;
}

.sitemap li a::before {
    display: none;
}

.content-top,
.content-bottom {
    margin: 1em 0;
}

p.has-background {
    padding: 12px;
}

:where(.wp-block-columns.is-layout-flex) {
    gap: 1em;
}

/************************************
** 投稿・固定ページで使うHTML要素
************************************/
table {
    margin-bottom: 20px;
    max-width: 100%;
    width: 100%;
    border-collapse: collapse;
    border-spacing: 0;
}

table th,
table td {
    position: relative;
    padding: 0.5em;
}

table th {
    background-color: var(--cocoon-xx-thin-color);
}

table tr:nth-of-type(2n+1) {
    background-color: var(--cocoon-xxx-thin-color);
}

table:not(.has-border-color) :where(th, td) {
    border: 1px solid var(--cocoon-x-thin-color);
}

table :is(th, td):has(.s-circle, .d-circle, .triangle, .cross) {
    height: 60px;
}

.s-circle:before,
.d-circle:before,
.triangle:before,
.cross:before {
    content: "";
    display: block;
    height: 40px;
    left: 50%;
    opacity: 0.3;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 40px;
}

.s-circle:before {
    background-color: #00bcd4;
    -webkit-mask-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M16 32q-3.313 0-6.219-1.25-2.906-1.281-5.078-3.453t-3.453-5.078q-1.25-2.906-1.25-6.219t1.25-6.219q1.281-2.906 3.453-5.078t5.078-3.453q2.906-1.25 6.219-1.25t6.219 1.25q2.906 1.281 5.078 3.453t3.453 5.078q1.25 2.906 1.25 6.219t-1.25 6.219q-1.281 2.906-3.453 5.078t-5.078 3.453q-2.906 1.25-6.219 1.25zM16 2.906q-2.719 0-5.094 1.031t-4.156 2.813-2.813 4.156-1.031 5.094 1.031 5.094 2.813 4.156 4.156 2.813 5.094 1.031 5.094-1.031 4.156-2.813 2.813-4.156 1.031-5.094-1.031-5.094-2.813-4.156-4.156-2.813-5.094-1.031z"></path></svg>');
}

.d-circle:before {
    background-color: #8bc34a;
    -webkit-mask-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M16 32q-3.313 0-6.219-1.25-2.906-1.281-5.078-3.453t-3.453-5.078q-1.25-2.906-1.25-6.219t1.25-6.219q1.281-2.906 3.453-5.078t5.078-3.453q2.906-1.25 6.219-1.25t6.219 1.25q2.906 1.281 5.078 3.453t3.453 5.078q1.25 2.906 1.25 6.219t-1.25 6.219q-1.281 2.906-3.453 5.078t-5.078 3.453q-2.906 1.25-6.219 1.25zM16 2.906q-2.719 0-5.094 1.031t-4.156 2.813-2.813 4.156-1.031 5.094 1.031 5.094 2.813 4.156 4.156 2.813 5.094 1.031 5.094-1.031 4.156-2.813 2.813-4.156 1.031-5.094-1.031-5.094-2.813-4.156-4.156-2.813-5.094-1.031zM16 26.875q-2.25 0-4.219-0.875-1.969-0.844-3.453-2.328t-2.328-3.453q-0.875-1.969-0.875-4.219t0.875-4.219q0.844-1.969 2.328-3.453t3.453-2.328q1.969-0.875 4.219-0.875t4.219 0.875q1.969 0.844 3.453 2.328t2.328 3.453q0.875 1.969 0.875 4.219t-0.875 4.219q-0.844 1.969-2.328 3.453t-3.453 2.328q-1.969 0.875-4.219 0.875zM16 8.063q-1.656 0-3.094 0.625t-2.516 1.703-1.703 2.516-0.625 3.094 0.625 3.094 1.703 2.516 2.516 1.703 3.094 0.625 3.094-0.625 2.516-1.703 1.703-2.516 0.625-3.094v0q0-1.656-0.625-3.094t-1.703-2.516-2.516-1.703-3.094-0.625v0 0z"></path></svg>');
}

.triangle:before {
    background-color: #ffc107;
    -webkit-mask-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M31.719 29.281h-31.313l15.656-27.094zM4.625 26.844h22.875l-11.438-19.813z"></path></svg>');
}

.cross:before {
    background-color: #f44336;
    -webkit-mask-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32"><path d="M16 18.125l-11.938 11.938-2.094-2.125 11.906-11.938-11.906-11.938 2.094-2.094 11.938 11.906 11.938-11.906 2.125 2.094-11.938 11.938 11.938 11.938-2.125 2.125-11.938-11.938z"></path></svg>');
}

.scrollable-table {
    -webkit-overflow-scrolling: touch;
    margin: 0;
}

.scrollable-table.stfc-sticky table:not(.wp-calendar-table) tr>*:first-child {
    background-color: #e9eff2;
    color: var(--cocoon-text-color);
    position: sticky;
    position: -webkit-sticky;
    left: 0;
    z-index: 2;
}

.scrollable-table table {
    border-collapse: collapse;
    max-width: none;
    margin: 0;
}

.scrollable-table th {
    background-color: #eee;
}

.scrollable-table th,
.scrollable-table td {
    white-space: nowrap;
}

.scrollable-table .has-fixed-layout th,
.scrollable-table .has-fixed-layout td {
    white-space: normal;
}

.scrollable-table {
    overflow-x: auto !important;
    overflow-y: hidden !important;
}

figure.wp-block-table table {
    width: auto;
}

figure.wp-block-table.alignwide table,
figure.wp-block-table.alignfull table {
    width: 100%;
}

.main figure.wp-block-table {
    width: auto;
    max-width: 100%;
    display: block;
}

figure.wp-block-table table {
    margin-right: auto;
}

figure.wp-block-table.aligncenter table {
    margin-left: auto;
}

figure.wp-block-table.aligncenter figcaption {
    text-align: center;
}

figure.wp-block-table.alignright table {
    margin-left: 0;
}

.scroll-hint-icon-wrap {
    overflow: hidden;
}

.article {
    margin-bottom: 2em;
}

.article a {
    color: #1967d2;
    text-decoration: underline;
    cursor: pointer;
}

.article dd {
    margin-left: 40px;
}

.article ul li,
.article ol li {
    margin: 0.2em;
}

.article ul ul,
.article ul ol,
.article ol ul,
.article ol ol {
    margin: 0;
    padding-top: 0;
    padding-bottom: 0;
    padding-right: 0;
}

.article .wp-block-gallery ul {
    padding-left: 0;
}

.article h1,
.article h2,
.article h3,
.article h4,
.article h5,
.article h6 {
    line-height: 1.25;
    font-weight: bold;
}

.article h4,
.article h5,
.article h6 {
    font-size: 20px;
    padding: 9px 10px;
}

.article h1 {
    font-size: 1.5em;
    padding: 0;
    margin-block-start: 0.83em;
    margin-block-end: 0.83em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
}

.article h2 {
    font-size: 24px;
    padding: 25px;
    background-color: var(--cocoon-xx-thin-color);
    border-radius: var(--cocoon-badge-border-radius);
}

.article h3 {
    border-left: 7px solid var(--cocoon-middle-thickness-color);
    border-right: 1px solid var(--cocoon-thin-color);
    border-top: 1px solid var(--cocoon-thin-color);
    border-bottom: 1px solid var(--cocoon-thin-color);
    font-size: 22px;
    padding: 12px 20px;
}

.article h4 {
    border-top: 2px solid var(--cocoon-thin-color);
    border-bottom: 2px solid var(--cocoon-thin-color);
}

.article h5 {
    border-bottom: 2px solid var(--cocoon-thin-color);
}

.article h6 {
    border-bottom: 1px solid var(--cocoon-thin-color);
}

.article .column-wrap h1,
.article .column-wrap h2,
.article .column-wrap h3,
.article .column-wrap h4,
.article .column-wrap h5,
.article .column-wrap h6 {
    margin-top: 0;
}

:root {
    --cocoon-twitter-color: #000000;
    --cocoon-mastodon-color: #6364FF;
    --cocoon-bluesky-color: #0085ff;
    --cocoon-misskey-color: #96d04a;
    --cocoon-facebook-color: #3b5998;
    --cocoon-hatebu-color: #2c6ebd;
    --cocoon-google-plus-color: #dd4b39;
    --cocoon-pocket-color: #ef4056;
    --cocoon-line-color: #00c300;
    --cocoon-linkedin-color: #0077b5;
    --cocoon-website-color: #002561;
    --cocoon-instagram-color: #405de6;
    --cocoon-pinterest-color: #bd081c;
    --cocoon-youtube-color: #cd201f;
    --cocoon-tiktok-color: #000000;
    --cocoon-note-color: #41c9b4;
    --cocoon-soundcloud-color: #ff8800;
    --cocoon-flickr-color: #111;
    --cocoon-amazon-color: #ff9900;
    --cocoon-twitch-color: #6441a4;
    --cocoon-rakuten-color: #bf0000;
    --cocoon-rakuten-room-color: #c61e79;
    --cocoon-slack-color: #e01563;
    --cocoon-github-color: #4078c0;
    --cocoon-codepen-color: #333;
    --cocoon-feedly-color: #2bb24c;
    --cocoon-rss-color: #f26522;
    --cocoon-red-color: #e60033;
    --cocoon-pink-color: #e95295;
    --cocoon-purple-color: #884898;
    --cocoon-deep-color: #55295b;
    --cocoon-indigo-color: #1e50a2;
    --cocoon-blue-color: #0095d9;
    --cocoon-light-blue-color: #2ca9e1;
    --cocoon-cyan-color: #00a3af;
    --cocoon-teal-color: #007b43;
    --cocoon-green-color: #3eb370;
    --cocoon-light-green-color: #8bc34a;
    --cocoon-lime-color: #c3d825;
    --cocoon-yellow-color: #ffd900;
    --cocoon-amber-color: #ffc107;
    --cocoon-orange-color: #f39800;
    --cocoon-deep-orange-color: #ea5506;
    --cocoon-brown-color: #954e2a;
    --cocoon-grey-color: #949495;
    --cocoon-blue-gray-color: #607d8b;
    --cocoon-black-color: #333333;
    --cocoon-white-color: #ffffff;
    --cocoon-watery-blue-color: #f3fafe;
    --cocoon-watery-yellow-color: #fff7cc;
    --cocoon-watery-red-color: #fdf2f2;
    --cocoon-watery-green-color: #ebf8f4;
    --cocoon-custom-background-color: inherit;
    --cocoon-custom-text-color: inherit;
    --cocoon-custom-border-color: #ccc;
    --cocoon-default-font: "Hiragino Kaku Gothic ProN", "Hiragino Sans", Meiryo, sans-serif;
    --cocoon-text-color: #333;
    --cocoon-pallid-text-color: #777;
    --cocoon-x-pallid-text-color: #999;
    --cocoon-default-text-size: 18px;
    --cocoon-text-size-s: 0.8em;
    --cocoon-basic-border-color: #ccc;
    --cocoon-three-d-border-colors: #e3e3e3 #cecece #b4b4b4 #d6d6d6;
    --cocoon-middle-thickness-color: #888;
    --cocoon-thin-color: #ddd;
    --cocoon-x-thin-color: #eee;
    --cocoon-xx-thin-color: #f5f6f7;
    --cocoon-xxx-thin-color: #fafbfc;
    --cocoon-current-background-color: #eee;
    --cocoon-select-background-color: #d8eaf2;
    --cocoon-basic-border-radius: 4px;
    --cocoon-badge-border-radius: 2px;
    --cocoon-middle-border-radius: 8px;
    --cocoon-box-padding: 1em 1.5em;
    --cocoon-list-padding: min(1%, 8px) min(2%, 16px) min(1%, 8px) min(3%, 24px);
    --cocoon-list-wide-padding: min(3%, 24px) min(5%, 40px) min(3%, 24px) min(7%, 56px);
    --cocoon-box-border-color: #47585c;
    --cocoon-sns-color: var(--cocoon-text-color);
    --card-ratio: 16/9;
}

.article h2,
.article h3,
.article h4,
.article h5,
.article h6 {
    margin-bottom: 1.62em
}

ul {
    list-style-type: disc;
}

ol,
ul {
    padding-left: 40px;
}

ol,
ul {
    box-sizing: border-box;
}


.article .micro-top {
    margin-bottom: .36em
}

.article .micro-bottom {
    margin-top: -1.62em
}

.article .micro-balloon {
    margin-bottom: .9em
}

.article .micro-bottom.micro-balloon {
    margin-top: -1.26em
}

pre {
    background-color: var(--cocoon-xx-thin-color);
    border: 1px solid var(--cocoon-thin-color);
    overflow: auto;
    padding: 10px;
}

.article p,
.demo .entry-content p,
.article dl,
.article ul,
.article ol,
.article blockquote,
.article pre,
.article table,
.article .toc,
.body .article,
.body .column-wrap,
.body .new-entry-cards,
.body .popular-entry-cards,
.body .navi-entry-cards,
.body .box-menus,
.body .ranking-item,
.body .rss-entry-cards,
.body .widget,
.body .author-box,
.body .blogcard-wrap,
.body .login-user-only,
.body .information-box,
.body .question-box,
.body .alert-box,
.body .information,
.body .question,
.body .alert,
.body .memo-box,
.body .comment-box,
.body .common-icon-box,
.body .blank-box,
.body .button-block,
.body .micro-bottom,
.body .caption-box,
.body .tab-caption-box,
.body .label-box,
.body .toggle-wrap,
.body .wp-block-image,
.body .booklink-box,
.body .kaerebalink-box,
.body .tomarebalink-box,
.body .product-item-box,
.body .speech-wrap,
.body .wp-block-categories,
.body .wp-block-archives,
.body .wp-block-archives-dropdown,
.body .wp-block-calendar,
.body .ad-area,
.body .wp-block-gallery,
.body .wp-block-audio,
.body .wp-block-cover,
.body .wp-block-file,
.body .wp-block-media-text,
.body .wp-block-video,
.body .wp-block-buttons,
.body .wp-block-columns,
.body .wp-block-separator,
.body .components-placeholder,
.body .wp-block-search,
.body .wp-block-social-links,
.body .timeline-box,
.body .blogcard-type,
.body .btn-wrap,
.body .btn-wrap a,
.body .block-box,
.body .wp-block-embed,
.body .wp-block-group,
.body .wp-block-table,
.body .scrollable-table,
.body .wp-block-separator,
.body .wp-block,
.body .video-container,
.comment-area,
.related-entries,
.pager-post-navi,
.comment-respond {
    margin-bottom: 1.8em;
}

@media screen and (max-width: 1023px) {
    .navi-footer {
        float: none;
        margin: 0;
        width: 100%;
    }

    .copyright {
        width: 100%;
    }

    .navi-footer-in>ul {
        justify-content: center;
        padding: 1em;
    }

    .footer-widgets {
        display: block;
    }

    .footer-widgets>div {
        width: 100%;
    }

    .content-in {
        display: block;
    }

    main.main,
    div.sidebar {
        display: block;
        margin: 10px;
        width: auto;
    }

    .footer-bottom-logo,
    .footer-bottom-content {
        float: none;
    }

    .footer-bottom-logo {
        line-height: 1;
    }

    .footer-bottom-content {
        text-align: center;
    }

    .fb-like-message {
        font-size: 0.8em;
        line-height: 1.4;
    }

    .sidebar-scroll {
        position: static;
    }

    .ba-fixed.header {
        background-position: top center;
    }

    .ba-fixed.header,
    .ba-fixed.appeal {
        background-attachment: scroll;
    }

    .header,
    .appeal {
        background-size: cover;
    }

    .footer-bottom-logo {
        position: relative;
        bottom: auto;
    }

    .logo-footer {
        margin: 8px auto;
    }

    .mobile-menu-buttons {
        display: flex;
    }

    .mobile-header-menu-buttons {
        top: 0;
        bottom: auto;
        justify-content: space-around;
        min-width: 46px;
        box-shadow: 0 0 5px darkgrey;
    }

    .has-logo-button .menu-button {
        width: 70px;
    }

    .has-logo-button .logo-menu-button {
        flex-grow: 99;
    }

    .no-mobile-sidebar .sidebar,
    .mobile-button-fmb .go-to-top-button,
    .no-mobile-header-logo #header .logo-header {
        display: none;
    }

    .mblt-footer-mobile-buttons,
    .mblt-header-and-footer-mobile-buttons {
        margin-bottom: 50px;
    }

    .scrollable-mobile-buttons {
        margin-bottom: 0;
    }

    .navi-footer-in>.menu-footer {
        justify-content: center;
    }

    .footer-bottom.fdt-left-and-right .footer-bottom-content {
        flex-direction: column;
    }

    .mblt-footer-mobile-buttons .go-to-top-button,
    .mblt-header-and-footer-mobile-buttons .go-to-top-button {
        display: none;
    }

    .navi-in .menu-pc {
        display: none;
    }

    .no-mobile-header-logo .header .header-in {
        min-height: auto;
    }

    #navi .navi-in>.menu-mobile {
        display: flex;
    }

    #navi .navi-in>.menu-mobile>.menu-item-has-description>a>.caption-wrap {
        height: 40px;
        line-height: 16px;
    }

    #navi .navi-in>.menu-mobile li {
        width: 50%;
        height: 40px;
        line-height: 40px;
    }

    #navi .navi-in>.menu-mobile li a {
        font-size: 14px;
    }

    #navi .navi-in>.menu-mobile li a:after {
        display: none;
    }

    .slicknav_menu {
        display: block;
    }

    /* モバイルヘッダー高さ分、余白を設ける */
    body:where(.mblt-header-mobile-buttons, .mblt-header-and-footer-mobile-buttons) {
        margin-top: 50px;
    }

    /* adminバーがある場合、その余白分を除いた余白とする */
    .admin-bar:where(.mblt-header-mobile-buttons, .mblt-header-and-footer-mobile-buttons) {
        margin-top: calc(50px - var(--wp-admin--admin-bar--height));
    }
}

@media screen and (max-width: 834px) {
    .navi-in>.menu-header {
        display: none;
    }

    main.main,
    div.sidebar {
        padding: 32px 16px;
        margin: 12px 0;
        border-width: 0;
    }

    .pr-label-s {
        left: 6px;
        padding: 1px 8px;
    }

    .article ul,
    .article ol {
        padding-left: 26px;
    }

    .entry-card-title,
    .related-entry-card-title {
        font-size: 17px;
    }

    .entry-card-snippet,
    .related-entry-card-snippet {
        font-size: 14px;
    }

    table th,
    table td {
        padding: 3px;
        font-size: var(--cocoon-text-size-s);
    }

    .blogcard-wrap {
        width: 100%;
    }

    .blogcard-thumbnail {
        width: 120px;
    }

    .blogcard-title {
        font-size: 0.9em;
        margin-bottom: 0;
    }

    .blogcard-snippet {
        font-size: 12px;
    }

    .blogcard-content {
        margin-left: 130px;
        margin-right: 0;
        max-height: 120px;
        min-height: 70px;
        font-size: 0.9em;
        line-height: 1.2;
    }

    .blogcard-footer {
        font-size: 14px;
    }

    .ib-right .blogcard-content,
    .eb-right .blogcard-content {
        margin-right: 31.6%;
        margin-left: 0;
    }

    .ss-top .sns-share-buttons a,
    .ss-bottom .sns-share-buttons a {
        font-size: 14px;
    }

    .ss-top .sns-share-buttons a .button-caption,
    .ss-bottom .sns-share-buttons a .button-caption {
        font-size: 14px;
        margin-left: 4px;
    }

    .sns-share.ss-top.ss-col-6 .sns-buttons,
    .sns-share.ss-bottom.ss-col-6 .sns-buttons {
        column-gap: 0.75%;
    }

    .sns-share.ss-top.ss-col-6 a,
    .sns-share.ss-bottom.ss-col-6 a {
        width: 32.5%;
    }

    .sns-follow-buttons {
        column-gap: 1%;
    }

    .sns-follow-buttons a {
        margin-right: 0;
        width: 49.5%;
    }

    /************************************
    ** 関連記事
    ************************************/
    .rect-vertical-card .related-list {
        column-gap: 1%;
    }

    .rect-vertical-card .related-entry-card-wrap {
        width: 49.5%;
    }

    .rect-mini-card .cat-label {
        font-size: 8px;
    }

    .rect-mini-card .related-entry-card-thumb {
        width: 30%;
    }

    .rect-mini-card .related-entry-card-content {
        margin-left: 32%;
    }

    .header-container-in.hlt-top-menu {
        flex-direction: column;
    }

    .ect-vertical-card .entry-card-wrap {
        width: 49%;
    }

    .header div.header-in {
        min-height: auto;
    }

    .footer-widgets {
        display: none;
    }

    .footer-widgets-mobile {
        display: block;
    }

    .widget_pc_text,
    .widget_pc_ad,
    .widget_pc_double_ads {
        display: none;
    }

    .widget_mobile_text,
    .widget_mobile_ad {
        display: block;
    }

    .fb-like-box {
        max-width: none;
        flex-direction: column;
    }

    .cta-box {
        font-size: 0.8em;
    }

    .cta-heading {
        font-size: 20px;
        padding: 8px;
    }

    .cta-left-and-right .cta-content,
    .cta-right-and-left .cta-content {
        display: block;
    }

    .cta-left-and-right .cta-content div,
    .cta-right-and-left .cta-content div {
        width: 100%;
    }

    .cta-message {
        margin-top: 1em;
    }

    .cta-button .btn-l,
    .cta-button .ranking-item-link-buttons a,
    .ranking-item-link-buttons .cta-button a {
        padding: 1em;
        font-size: 16px;
    }

    .sbp-l {
        margin-right: auto;
    }

    .sbp-r {
        margin-left: auto;
    }

    .speech-wrap.sbs-think.sbp-l {
        margin-right: 0;
    }

    .speech-wrap.sbs-think.sbp-r {
        margin-left: 0;
    }

    .admin-panel {
        padding: 5px;
        font-size: 14px;
        line-height: 1.4;
    }

    .admin-panel>div {
        margin: 3px;
    }

    .apdt-pc-only {
        display: none;
    }

    .apdt-mobile-only {
        display: flex;
    }

    .ranking-item-image-tag {
        max-width: 30%;
    }

    #header .site-name-text {
        font-size: 22px;
    }

    .header-container-in.hlt-top-menu .header .header-in,
    .header-container-in.hlt-top-menu .header .logo-text {
        display: block;
        height: 100%;
    }

    .header-container-in.hlt-top-menu .site-name-text {
        white-space: normal;
    }

    .header-container-in.hlt-top-menu .logo-header {
        max-height: none;
    }

    .eye-catch .cat-label {
        font-size: 10px;
        padding: 2px 4px;
    }

    textarea {
        min-height: 160px;
    }

    .navi-footer-in>.menu-footer li.menu-item {
        width: 32%;
        border: 0;
        margin: 2px;
        display: flex;
        flex: 1 1 auto;
        text-align: center;
    }

    .navi-footer-in>.menu-footer li.menu-item a {
        padding: 0;
        width: 100%;
    }

    .footer-bottom.fdt-left-and-right .footer-bottom-content {
        flex-direction: column;
    }

    #wpf-form-wrapper .mce-edit-area iframe {
        min-height: 200px;
    }

    .content {
        margin-top: 10px;
    }

    .product-item-thumb-l,
    .swatchimages {
        display: none;
    }

    .image-content amp-img {
        min-width: 360px;
        max-width: 360px;
    }

    .btn-l,
    .ranking-item-link-buttons a,
    .btn-wrap.btn-wrap-l>a {
        font-size: 14px;
    }

    .slick-dots {
        display: none !important;
    }

    .carousel-entry-card-title {
        font-size: 0.7em;
    }

    .header-container-in.hlt-top-menu.hlt-tm-small .logo-header {
        height: auto;
    }

    .recommended .navi-entry-cards {
        flex-wrap: wrap;
    }

    .recommended .navi-entry-cards a {
        width: 50%;
    }

    .recommended.rcs-card-margin a {
        margin: 1%;
        width: 48%;
    }

    .recommended.rcs-card-margin a:last-child {
        margin-right: 1%;
    }

    .container .column-wrap {
        flex-direction: column;
    }

    .container .column-wrap>div {
        width: 100%;
    }

    .box-menus .box-menu {
        width: 50%;
    }

    .wwa .box-menus {
        margin-left: 10px;
        margin-right: 10px;
    }

    /************************************
    ** Facebookページを「いいね！」するエリアのスタイル
    ************************************/
    .fb-like-balloon-body {
        margin-right: 100px;
        font-size: 1em;
        line-height: 1.2em;
    }

    .fb-like-balloon-body::after {
        display: none;
    }

    .index-tab-buttons {
        flex-wrap: wrap;
    }

    .index-tab-buttons .index-tab-button {
        width: 49%;
        margin: 0.5%;
    }

    .list-columns {
        flex-direction: column;
    }

    .list-columns .list-column {
        width: 100%;
    }

    .sidebar .ad-vertical .ad-usual {
        display: none;
    }

    .sidebar .ad-vertical .ad-additional-vertical {
        display: block;
    }

    .booklink-box,
    .kaerebalink-box,
    .tomarebalink-box,
    .product-item-box {
        padding: 10px 10px 24px;
        display: block;
    }

    .booklink-image,
    .kaerebalink-image,
    .tomarebalink-image,
    .product-item-thumb {
        width: 120px;
        min-width: 120px;
        margin-right: 3% !important;
        float: left !important;
        height: 100% !important;
    }

    .booklink-link2,
    .kaerebalink-link1,
    .tomarebalink-link1,
    .product-item-buttons {
        display: flex;
        flex-wrap: wrap;
    }

    .booklink-link2>*,
    .kaerebalink-link1>*,
    .tomarebalink-link1>*,
    .product-item-buttons>* {
        width: auto;
        margin: 0 0.5% !important;
    }

    .booklink-link2>*>a,
    .kaerebalink-link1>*>a,
    .tomarebalink-link1>*>a,
    .product-item-buttons>*>a {
        width: auto;
        font-size: 11px;
    }

    .product-item-box::after {
        font-size: 18px;
    }
}

@media screen and (max-width: 480px) {

    h2,
    h3 {
        font-size: 20px;
    }

    .related-entry-heading,
    .comment-title {
        margin: 1.2em 0 1em;
    }

    .entry-card,
    .related-entry-card,
    .e-card-title {
        line-height: 1.3;
    }

    .e-card-title {
        font-size: 16px;
    }

    .e-card-snippet {
        font-size: 13px;
    }

    .cat-label {
        font-size: 10px;
        padding: 1px 3px;
    }

    .page-numbers {
        width: 34px;
        height: 34px;
        line-height: 34px;
    }

    .pagination-next-link,
    .comment-btn {
        font-size: 1em;
    }

    .related-entry-card-thumb {
        width: 38%;
    }

    .related-entry-card-content {
        margin-left: 40%;
        font-size: 0.9em;
    }

    /*本文の見出し*/
    .entry-title,
    .article h2,
    .article h3,
    .article h4,
    .article h5,
    .article h6 {
        padding: 0.6em;
    }

    .entry-title,
    .article h2 {
        font-size: 20px;
        padding: 0.6em 0.8em;
    }

    .article h3 {
        font-size: 18px;
    }

    .article h4,
    .article h5,
    .article h6 {
        font-size: 16px;
    }

    .entry-title {
        margin: 0;
        margin-bottom: 6px;
    }

    body {
        font-size: 16px;
    }

    .pager-post-navi a figure {
        min-width: 30%;
        max-width: 30%;
    }

    .pager-post-navi a.prev-post .prev-post-title {
        margin-left: 1.6%;
    }

    .pager-post-navi a.next-post .next-post-title {
        margin-right: 1.6%;
    }

    .pager-post-navi a::before {
        font-size: 16px;
    }

    .rect-mini-card .related-entry-card-wrap {
        width: 100%;
    }

    .pager-post-navi.post-navi-square {
        flex-direction: column;
    }

    .pager-post-navi.post-navi-square a {
        width: 100%;
        padding: 7px;
    }

    .pager-post-navi.post-navi-square a:last-child {
        border-top-width: 0;
        border-left-width: 1px;
    }

    .author-box {
        font-size: 0.9em;
        line-height: 1.3;
    }

    .author-box .author-thumb {
        width: 24%;
    }

    .author-box .author-content {
        margin-left: 26%;
    }

    .speech-person {
        width: 16%;
        min-width: 16%;
    }

    .speech-name {
        font-size: 10px;
    }

    .speech-wrap .speech-balloon {
        padding: 7px;
        max-width: 84%;
    }

    .speech-wrap .speech-balloon::before,
    .speech-wrap .speech-balloon::after {
        border-bottom-width: 7px;
        border-right-width: 7px;
        border-top-width: 7px;
    }

    .speech-wrap .speech-balloon::before {
        left: -7px;
    }

    .speech-wrap .speech-balloon::after {
        left: -5px;
    }

    .speech-wrap:not(.not-nested-style).sbp-l .speech-balloon {
        margin-left: 10px;
    }

    .speech-wrap:not(.not-nested-style).sbp-r .speech-balloon {
        margin-right: 10px;
    }

    .speech-wrap:not(.not-nested-style).sbp-r .speech-balloon::before {
        right: -7px;
        left: auto;
        border-left-width: 7px;
    }

    .speech-wrap:not(.not-nested-style).sbp-r .speech-balloon::after {
        border-left-width: 7px;
        right: -5px;
        left: auto;
    }

    .speech-wrap.sbs-think:not(.not-nested-style).sbp-l .speech-balloon {
        margin-left: 28px;
    }

    .speech-wrap.sbs-think:not(.not-nested-style) .speech-balloon::before {
        top: 4px;
        left: -19px;
        width: 12px;
        height: 12px;
    }

    .speech-wrap.sbs-think:not(.not-nested-style) .speech-balloon::after {
        top: 16px;
        width: 6px;
        height: 6px;
        left: -27px;
    }

    .speech-wrap.sbs-think.sbp-r:not(.not-nested-style) .speech-balloon {
        margin-right: 28px;
    }

    .speech-wrap.sbs-think.sbp-r:not(.not-nested-style) .speech-balloon::before {
        border: 2px solid var(--cocoon-basic-border-color);
        left: auto;
        right: -19px;
    }

    .speech-wrap.sbs-think.sbp-r:not(.not-nested-style) .speech-balloon::after {
        border: 2px solid var(--cocoon-basic-border-color);
        left: auto;
        right: -27px;
    }

    .cocoon-block-balloon.not-nested-style.sbs-line>.speech-balloon::after {
        left: -5px;
    }

    .cocoon-block-balloon.not-nested-style.sbp-l>.speech-balloon {
        margin-left: 10px;
    }

    .cocoon-block-balloon.not-nested-style.sbp-r>.speech-balloon,
    .cocoon-block-balloon.not-nested-style.sbp-r.sbs-line>.speech-balloon {
        margin-right: 10px;
    }

    .cocoon-block-balloon.not-nested-style.sbp-r>.speech-balloon::before,
    .cocoon-block-balloon.not-nested-style.sbp-r.sbs-line>.speech-balloon::before {
        right: -7px;
        left: auto;
        border-left-width: 7px;
    }

    .cocoon-block-balloon.not-nested-style.sbp-r>.speech-balloon::after,
    .cocoon-block-balloon.not-nested-style.sbp-r.sbs-line>.speech-balloon::after {
        border-left-width: 7px;
        right: -5px;
        left: auto;
    }

    .cocoon-block-balloon.not-nested-style.sbs-think>.speech-balloon::before {
        top: 4px;
        left: -19px;
        width: 12px;
        height: 12px;
    }

    .cocoon-block-balloon.not-nested-style.sbs-think>.speech-balloon::after {
        top: 16px;
        width: 6px;
        height: 6px;
        left: -27px;
    }

    .cocoon-block-balloon.not-nested-style.sbs-think.sbp-l>.speech-balloon {
        margin-left: 28px;
    }

    .cocoon-block-balloon.not-nested-style.sbs-think.sbp-r>.speech-balloon {
        margin-right: 28px;
    }

    .cocoon-block-balloon.not-nested-style.sbs-think.sbp-r>.speech-balloon::before,
    .cocoon-block-balloon.not-nested-style.sbs-think.sbp-r>.speech-balloon::after {
        border-width: 2px;
        left: auto;
    }

    .cocoon-block-balloon.not-nested-style.sbs-think.sbp-r>.speech-balloon::before {
        right: -19px;
    }

    .cocoon-block-balloon.not-nested-style.sbs-think.sbp-r>.speech-balloon::after {
        right: -27px;
    }

    .admin-panel>div {
        margin-bottom: 0.6em;
        padding: 2px;
    }

    .menu-content {
        max-width: 84%;
    }

    .ranking-item-img-desc,
    .ranking-item-link-buttons {
        display: block;
    }

    .ranking-item-image-tag {
        max-width: 100%;
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .ranking-item-name-text,
    .widget_item_ranking .main-widget-label {
        font-size: 20px;
    }

    a.facebook-follow-button {
        top: 3px;
    }

    #header .site-name-text {
        font-size: 18px;
    }

    .tagline {
        font-size: 12px;
    }

    .navi-footer-in>.menu-footer li.menu-item {
        width: 46%;
    }

    .blogcard-content {
        font-size: 0.84em;
    }

    .blogcard-snippet {
        font-size: 0.74em;
    }

    .ect-vertical-card.sp-entry-card-1-column .entry-card-wrap {
        display: block;
    }

    .ect-vertical-card.sp-entry-card-1-column>* {
        width: 100%;
    }

    #bbpress-forums .form-allowed-tags code {
        width: 100% !important;
    }

    .entry-card-snippet {
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    .no-sp-snippet .entry-card-snippet {
        display: none;
    }

    .no-sp-snippet-related .related-entry-card-snippet {
        display: none;
    }

    .e-card-meta {
        margin-top: 2px;
    }

    .ect-entry-card .card-thumb,
    .rect-entry-card .card-thumb,
    .ect-big-card-first .card-thumb {
        margin-bottom: 6px;
    }

    .ect-entry-card .card-content,
    .rect-entry-card .card-content,
    .ect-big-card-first .card-content {
        margin: 0;
    }

    .ect-entry-card .card-snippet,
    .rect-entry-card .card-snippet,
    .ect-big-card-first .card-snippet {
        margin-bottom: 0;
        clear: both;
    }

    .ect-entry-card .card-meta,
    .rect-entry-card .card-meta,
    .ect-big-card-first .card-meta {
        clear: both;
    }

    .list .ect-vertical-card>* {
        width: 48%;
        padding: 0.5% !important;
        margin: 0.5% 0.5% 1.5% !important;
    }

    .sp-display-none {
        display: none !important;
    }

    .image-content amp-img {
        min-width: 200px;
        max-width: 200px;
    }

    .blogcard {
        padding-top: 3%;
    }

    .is-style-information-box,
    .is-style-question-box,
    .is-style-alert-box,
    .is-style-memo-box,
    .is-style-comment-box,
    .is-style-ok-box,
    .is-style-ng-box,
    .is-style-good-box,
    .is-style-bad-box,
    .is-style-profile-box,
    .information-box,
    .question-box,
    .alert-box,
    .information,
    .question,
    .alert,
    .memo-box,
    .comment-box,
    .common-icon-box {
        padding: 28px 12px 12px;
    }

    .is-style-information-box.has-background,
    .is-style-question-box.has-background,
    .is-style-alert-box.has-background,
    .is-style-memo-box.has-background,
    .is-style-comment-box.has-background,
    .is-style-ok-box.has-background,
    .is-style-ng-box.has-background,
    .is-style-good-box.has-background,
    .is-style-bad-box.has-background,
    .is-style-profile-box.has-background,
    .information-box.has-background,
    .question-box.has-background,
    .alert-box.has-background,
    .information.has-background,
    .question.has-background,
    .alert.has-background,
    .memo-box.has-background,
    .comment-box.has-background,
    .common-icon-box.has-background {
        padding: 28px 12px 12px;
    }

    .is-style-information-box::before,
    .is-style-question-box::before,
    .is-style-alert-box::before,
    .is-style-memo-box::before,
    .is-style-comment-box::before,
    .is-style-ok-box::before,
    .is-style-ng-box::before,
    .is-style-good-box::before,
    .is-style-bad-box::before,
    .is-style-profile-box::before,
    .information-box::before,
    .question-box::before,
    .alert-box::before,
    .information::before,
    .question::before,
    .alert::before,
    .memo-box::before,
    .comment-box::before,
    .common-icon-box::before {
        padding: 0;
        top: 0.7em;
        left: 50%;
        margin-left: -0.5em;
        font-size: 18px;
        border: none;
        width: auto;
    }

    .container .blank-box {
        margin-left: 0;
        margin-right: 0;
    }

    .timeline-box .timeline {
        padding-left: 10px;
    }

    .timeline>li.timeline-item {
        overflow: visible;
        border-left: 3px #e5e5d1 solid;
    }

    .timeline-item-label {
        width: auto;
        float: none;
        text-align: left;
        padding-left: 16px;
    }

    .timeline-item-content {
        width: auto;
        padding: 8px;
        float: none;
        border: none;
    }

    .timeline-item::before {
        left: -12px;
        top: 19px;
        width: 21px;
        height: 21px;
    }

    .fb-like-balloon-body {
        font-size: 0.8em;
        padding: 5px;
        line-height: 1.1em;
        margin-right: 0;
        height: 70px;
    }

    .fb-like-balloon-button {
        float: none;
        width: 100%;
        height: 30px;
        display: block;
        padding-top: 0;
        line-height: 24px;
    }

    div.fb-like-mobile {
        display: block;
    }

    div.fb-like-pc {
        display: none;
    }

    .pis-m .si-thumb {
        width: 30%;
    }

    .search-form div {
        padding: 3px 5px;
        font-size: 75%;
    }

    .search-form div.sform {
        min-width: 180px;
    }

    .search-form div.sbtn {
        padding-left: 5px;
        padding-right: 10px;
    }

    .search-form div.sbtn::after {
        font-size: 20px;
        margin-left: -10px;
    }

    div#bbpress-forums div.bbp-reply-author img.avatar {
        position: static;
    }

    div#bbpress-forums .bbp-body div.bbp-reply-author {
        padding-left: 0;
        width: 80px;
        text-align: center;
    }

    div#bbpress-forums .bbp-body div.bbp-reply-content {
        clear: none;
        margin: 0;
    }

    div#bbpress-forums .bbp-body div.bbp-reply-content p {
        margin-top: 0;
    }

    .appeal .appeal-in {
        min-height: 200px;
    }

    .booklink-image,
    .kaerebalink-image,
    .tomarebalink-image,
    .product-item-thumb {
        width: 80px;
        min-width: 80px;
    }

    .booklink-info,
    .kaerebalink-info,
    .tomarebalink-info,
    .product-item-content {
        width: auto !important;
        overflow: visible !important;
        text-align: center !important;
        padding: 0 !important;
    }

    .booklink-link2>*,
    .kaerebalink-link1>*,
    .tomarebalink-link1>*,
    .product-item-buttons>* {
        width: 100%;
        margin: 0 0.5% !important;
    }
}

@media screen and (max-width: 356px) {

    .ect-vertical-card .entry-card-wrap,
    .rect-vertical-card .related-entry-card-wrap,
    .ect-2-columns>*,
    .ect-3-columns>*,
    .fpt-2-columns>*,
    .fpt-3-columns>* {
        width: 100% !important;
    }
}